import React, { useContext } from "react";
import { Context } from "../state/appContext";
import { AuthHelper } from "../helpers/auth.helper";
import { ProfileComponent } from "../components/profile/profile.component";

export const Profile = () => {
  const { store, actions } = useContext(Context);
 
  const handleSubmit = () => {

  }

  return (
    <section className="container">
        <AuthHelper />
      <h3>Welcome {store.user?.emmail}!</h3>
        <ProfileComponent />
     
    </section>
  );
};
