import React from "react";
import './hero.css'
import '../../index.css'
export const Hero = () => {
  return (
    <section className="transparent container text-center hero-wrapper mx-auto shadow align-content-center">
    <div className="container p-5   row">
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 my-5 d-flex align-items-center justify-content-center">

      <p className="hola pe-3">HOLA!</p>
      </div>
      <div className= {`border-start ps-5 col-sm-12 col-md-12 col-lg-12 col-xl-6 my-5 text-start`} >
        <h1 className="hero-name">JAVIER SEIGLIE</h1>
        {/* <h2 className="fs-3 mt-3">
          Soy un desarrollador Full Stack en Sevilla, España!
        </h2> */}
        <h3 className="mt-3 fs-2">
          Estás a unos pasos de construir tu idea, contáctame y estarás un paso más cerca!
        </h3>
        
      </div>
    </div>
  </section>
  
  );
};
