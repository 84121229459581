import React from "react";

export const PoliticaCookies = () => {
  return (
    <section className=" text-start container fs-5 pt-3 bg-alpha ">
      <h3 className="fs-2 text-center mt-5">Política de cookies</h3>
      <article className="p-5">
        <p className=" lead fs-4 fw-bold my-3" >
           ¿Qué son las cookies?
          
        </p>
        <p className=" my-2 lh-sm">
          Las cookies son pequeñas piezas de información que se almacenan en el
          navegador de tu ordenador, tableta o teléfono móvil cuando visitas un
          sitio web, para facilitar y personalizar la navegación
        </p>
        <p className=" my-2 lh-sm fw-bold fs-4">
        
        ¿Qué hacen las cookies?

        </p>
        <p className=" my-2 lh-sm">
          Las cookies no te identifican personalmente y no suponen ningún riesgo
          para tu ordenador o dispositivo móvil. No son virus ni software espía.
          Se trata de herramientas utilizadas por los sitios web que visita para
          mejorar su navegación, para que sus datos viajen por la red seguros,
          para ayudarnos a entender qué información de nuestra web es más útil
          para nuestros usuarios o para saber qué ofertas pueden ser de su
          interés. Nunca se almacenarán datos personales como nombre, dirección,
          teléfono, etc., a menos que nos lo facilite.
        </p>
        <p className=" my-2 lh-sm">
          Si decide no permitir el uso de cookies de esta página puede que
          algunas características de la web no funcionen correctamente y,
          además, no podremos adaptar la información que le ofrecemos a sus
          intereses personales.
        </p>
        <p className=" lead fs-4 fw-bold my-3">
        
          ¿Para qué usamos cookies?
    
        </p>
        <p className=" my-2 lh-sm">
          En nuestra página utilizamos las cookies con distintas finalidades:
        </p>
        <ul>
          <li>
            Técnicas: Son necesarias para que pueda navegar a través de nuestra
            página de forma correcta.
          </li>
          <li>
            De análisis: Nos permiten entender cómo navegas por nuestra web, ver
            qué funciona y qué no, mejorar la página web, calcular cuánta gente
            visita el site, etc., se usan con el fin de mejorar los servicios
            ofrecidos por SEIGLIE. Esta información es anónima, y
            solo la necesitamos para fines estadísticos.
          </li>
        </ul>
        <p className=" lead fs-4 fw-bold my-3">
          <strong>Cookies propias</strong>
        </p>
        <p className=" my-2 lh-sm">
          <strong>c-se:</strong> Son unas cookies propias del sistema del sitio
          web. Estas cookies sirven para verifica quien eres y si ya estas
          logueado en el sitio web o no. Es una cookie técnica. Expira al año.
        </p>
    
        <p className=" lead fs-4 fw-bold my-3">
          <strong>Cookies de terceros</strong>
        </p>
        <p className=" my-2 lh-sm">
          <strong>_ga y _ga_con:</strong> Cookie usada para distinguir a los usuarios, es
          una cookie de terceros: Google Analytics/Google Tags y es de tipo analítica.
          Expira a los dos años.
        </p>
    
        
        <p className=" lead fs-4 fw-bold my-3">
         ¿Quieres desactivar las cookies de tu navegador?
        </p>
        <p className=" my-2 lh-sm">
          Generalmente los navegadores permiten las cookies por defecto. Cuando
          navegas por nuestra página estás aceptando el uso de las cookies. Sin
          embargo puedes modificar tus preferencias en cualquier momento.
        </p>
        <p className=" my-2 lh-sm">Te contamos como hacerlo aquí:</p>
        <p className=" lead fs-5">
          Internet Explorer
        </p>
        <p className=" my-2 lh-sm">
          Haga clic en el botón Herramientas de Internet Explorer y, después, en
          Opciones de Internet.
          <br />
          Haga clic en la pestaña Privacidad y, a continuación, mueva el control
          deslizante hasta el nivel de privacidad que desea. Tras ello, haga
          clic en Aceptar.
          <br />A medida que vaya moviendo el control deslizante, Internet
          Explorer le ofrecerá una descripción de los tipos de cookies que se
          bloquean o permiten en cada nivel de privacidad.
        </p>
        <p className=" lead fs-5">
          Firefox
        </p>
        <p className=" my-2 lh-sm">
          En la parte superior de la ventana de Firefox, haga clic en el botón
          Firefox y seleccione Opciones.
          <br />
          Seleccione el panel Privacidad.
          <br />
          En Firefox seleccione: Usar una configuración personalizada para el
          historial.
          <br />
          Marque la opción Aceptar cookies para activarlas, y desmárquela para
          desactivarlas.
          <br />
          Elija durante cuánto tiempo las cookies serán almacenadas:
          <br />
          Mantener hasta que: caduquen: la cookie será borrada cuando alcance su
          fecha de caducidad, que es configurada por el sitio que envió la
          cookie.
          <br />
          Mantener hasta que: cierre Firefox: las cookies que son almacenadas en
          tu ordenador serán borradas cuando se cierre Firefox.
          <br />
          Mantener hasta que: preguntar siempre: muestra una alerta cada vez que
          un sitio web intenta enviar una cookie preguntando si deseas
          almacenarla.
          <br />
          Haga clic en Aceptar para cerrar la ventana de Opciones.
        </p>
        <p className=" lead fs-5">
          Chrome
        </p>
        <p className=" my-2 lh-sm">
          Haga clic en el menú de Chrome en la barra de herramientas del
          navegador.
          <br />
          Seleccione Configuración.
          <br />
          Haga clic en Mostrar opciones avanzadas.
          <br />
          En la sección "Privacidad", haga clic en el botón Configuración de
          contenido.
          <br />
          En la sección "Cookies", puedes configurar las opciones que se indican
          a continuación:
          <br />
          Eliminar cookies.
          <br />
          Cómo bloquear las cookies de forma predeterminada.
          <br />
          Cómo permitir las cookies de forma predeterminada.
          <br />
          Guardar cookies y datos de sitios de forma predeterminada hasta cerrar
          el navegador.
          <br />
          Incluir excepciones de cookies de dominios o sitios web específicos.
        </p>
        <p className=" lead fs-5">
        Safari
        </p>
        <p className=" my-2 lh-sm">
          Vaya a Preferencias y, en el panel Privacidad, elija Bloquear Cookies.
          <br />
          Seleccione la opción que prefiera:
          <br />
          De publicidad y terceros.
          <br />
          Siempre
          <br />
          Nunca
        </p>
        <p className=" lead fs-5">
          Opera
        </p>
        <p className=" my-2 lh-sm">
          Acceda a la sección Configuración &gt; Opciones &gt; Avanzado &gt;
          Cookies
          <br />
          Aceptar cookies. Se aceptan todos (predeterminado)
          <br />
          Aceptar cookies sólo del sitio visitado. Se rechazan los cookies de
          terceros, para dominios externos al que se está visitando.
          <br />
          No aceptar cookies nunca. Se rechazan todas las cookies.
        </p>
        <p className=" lead fs-5">
          Iphone
        </p>
        <p className=" my-2 lh-sm">
          En la pantalla de inicio, selecciona Ajustes &gt; Safari y, a
          continuación, realiza una de estas acciones:
          <br />
          Pulse Aceptar cookies y elige la opción que prefieras:
          <br />
          "Nunca"
          <br />
          "De las visitadas"
          <br />
          "Siempre"
          <br />
          Para borrar todas las cookies de Safari, pulse Ajustes &gt; Safari
          &gt; Borrar cookies y datos
        </p>
        <p className=" lead fs-5">
        Android
        </p>
        <p className=" my-2 lh-sm">
          Abra el navegador de Android, pulse la tecla Menú y acceda a la opción
          Ajustes.
          <br />
          En la sección Seguridad y Privacidad active o desactive la casilla
          Aceptar cookies.
          <br />
          Reinicie el navegador para que los cambios sean efectivos.
        </p>
        <p className=" lead fs-5">
          Windows Phone
        </p>
        <p className=" my-2 lh-sm">
          En Internet Explorer, pulse en Más &gt; Configuración. También puede
          obtener acceso a la configuración de Internet Explorer a través de
          Configuración, en la lista de aplicaciones.
          <br />
          Seleccione o desactive la opción Permitir cookies en el teléfono para
          permitir o bloquear el almacenamiento de cookies.
        </p>
        <p className=" lead fs-4 fw-bold my-3">
        
              ¿Se pueden producir modificaciones de la Política de Cookies?
        
        </p>
        <p className=" my-2 lh-sm">
          La Web puede modificar esta Política de Cookies en función de las
          exigencias legislativas, reglamentarias, o con la finalidad de adaptar
          dicha política a las instrucciones dictadas por la Agencia Española de
          Protección de Datos, por ello se aconseja a los usuarios que la
          visiten periódicamente.
        </p>
        <p className=" my-2 lh-sm">
          Cuando se produzcan cambios significativos en esta Política de
          Cookies, se comunicarán a los usuarios bien mediante la web bien a
          través de correo electrónico a los usuarios registrados.
        </p>
      </article>
    </section>
  );
};
