import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [show, setShow] = useState("");

  const handleMouseEnter = (val) =>
    window.innerWidth > 767 && setShow((prev) => (prev = val));

  const handleMouseLeave = (e) =>
    window.innerWidth > 767 && setShow((prev) => (prev = ""));

  return (
    <footer className="d-flex row text-center text-body-secondary bg-transparent align-items-center">
      <div
        className={`col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex ${
          window.innerWidth < 768
            ? "place-content-center justify-content-center text-center"
            : "text-start justify-content-start place-content-start"
        } `}
      >
        <ul
          className={`ms-2 footer-ul-link nav mb-2 mt-5  flex-sm-row flex-md-column flex-lg-column flex-xl-column ${
            window.innerWidth < 768
              ? "place-content-center justify-content-center text-center"
              : "text-start "
          }`}
        >
          <li
            className="nav-item p-1"
            name="github"
            onMouseOver={() => handleMouseEnter("github")}
            onMouseOut={handleMouseLeave}
          >
            <a
              rel="noreferrer"
              target="_blank"
              className="nav-link"
              href="https://www.github.com/jseiglie"
            >
              <span className="fa-xl fa-brands fa-github"></span>
              <span className="ms-2">
                {show === "github" ? "/jseiglie" : ""}
              </span>
            </a>
          </li>
          <li
            className="nav-item p-1"
            name="x"
            onMouseOver={() => handleMouseEnter("x")}
            onMouseOut={handleMouseLeave}
          >
            <a
              rel="noreferrer"
              target="_blank"
              className="nav-link"
              href="https://www.x.com/javierseiglie"
            >
              <span className="fa-xl fa-brands fa-x-twitter"></span>
              <span className="ms-2">
                {show === "x" ? "@javierseiglie" : ""}
              </span>
            </a>
          </li>
          <li
            className="nav-item p-1"
            name="discord"
            onMouseOver={() => handleMouseEnter("discord")}
            onMouseOut={handleMouseLeave}
          >
            <a
              rel="noreferrer"
              target="_blank"
              className="nav-link"
              href="https://discord.gg/45E7nMYU"
            >
              <span className="fa-xl fa-brands fa-discord"></span>
              <span className="ms-2">{show === "discord" ? show : ""}</span>
            </a>
          </li>
          <li
            className="nav-item p-1"
            name="youtube"
            onMouseOver={() => handleMouseEnter("youtube")}
            onMouseOut={handleMouseLeave}
          >
            <a rel="noreferrer" target="_blank" className="nav-link" href="https://www.youtube.com/@javierseiglie">
              <span className="fa-xl fa-brands fa-youtube"></span>
              <span className="ms-2">{show === "youtube" ? show : ""}</span>
            </a>
          </li>
          <li
            className="nav-item p-1"
            name="linkedin"
            onMouseOver={() => handleMouseEnter("linkedin")}
            onMouseOut={handleMouseLeave}
          >
            <a
              rel="noreferrer"
              target="_blank"
              className="nav-link"
              href="https://www.linkedin.com/in/javierseiglie"
            >
              <span className="fa-xl fa-brands fa-linkedin"></span>
              <span className={"ms-2"}>
                {show === "linkedin" ? "/javierseiglie" : ""}
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 align-self-end  ">
        <a  href="#navbar">
          <span className="mb-3 fa-solid fa-angles-up circle-up"></span>
        </a>

        <p className="small text-center my-3">
          All product names, logos, brands, trademarks and registered trademarks
          are property of their respective owners.
        </p>
        <div className="d-flex justify-content-center ">

        <Link className="nav nav-item justify-content-center mb-2 mx-3" to={'/cookie_policy'}>Política de cookies</Link>
        <Link className="nav nav-item justify-content-center mb-2 mx-3" to={'/aviso_legal'}>Aviso legal</Link>
        </div>
      </div>

      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <a href="#navbar">
          <figure className=" mt-5">
            <img
              fetchpriority="high"
              width="190"
              height="auto"
              src="https://res.cloudinary.com/dchrsbres/image/upload/v1726135665/seiglie/brand/webp/logo192_ecpctt-removebg-preview_r9pwsp_lcqapr.webp"
              alt="Seiglie logo"
            />
            <figcaption hidden>seiglie logo</figcaption>
          </figure>
        </a>
      </div>
    </footer>
  );
};
