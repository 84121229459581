import { getUser, uploadImage, userLoginRegister, userLogout } from "./useStories";

const getState = ({ getStore, getActions, setStore }) => {
  return {
    store: {
      test: "ok",
      auth: false,
    },
    actions: {
      test: () =>
        fetch("/api/service/test")
          .then((resp) => resp.json())
          .then((data) => console.log(data)),
      logout: () => userLogout(setStore),
      loginRegister: async (endpoint, credentials) => {
        try {
          const res = await userLoginRegister(setStore, endpoint, credentials);
          if (!res.success) throw new Error("Something went wrong");
          setStore({ user: res.user, auth: res.auth });
          return true;
        } catch (error) {
          console.log(error);
        }
      },
      getUser: async () => {
        try {
          const res = await getUser();
          console.log(res);
          
          if (!res.success) throw new Error("Something went wrong");
          setStore({ user: res.user, auth: res.auth });
        } catch (error) {
          console.error(error);
        }
      },
      uploadAvatar: async (img) => {
        try {
          if (!img) throw new Error("Avatar missing");
          const res = await uploadImage(img);
          console.log(res)
          if (!res.success) throw new Error("Something went wrong");
          setStore({ user: res.user });
          return {success: true}
        } catch (error) {
          console.error(error);

        }
      }
    },
  };
};

export default getState;
