import React from "react";
import "./colab.css";
import '../../index.css'
import { Link } from "react-router-dom";
export const Colab = () => {

  const colabs = [
    {
      id: 1,
      img: "https://res.cloudinary.com/dchrsbres/image/upload/v1725524050/seiglie/colab/torsi_z1tglc.png",
      brand: "Torsi",
      description: "English School based in South Africa",
    },
    {
        id: 5,
        img: "https://res.cloudinary.com/dchrsbres/image/upload/v1725524050/seiglie/colab/4Geeks_zrloz3.jpg",
        brand: "4 Geeks Academy",
        description: "Coding bootcamp",
      },
    {
      id: 2,
      img: "https://res.cloudinary.com/dchrsbres/image/upload/v1725524050/seiglie/colab/gites_p0tqur.jpg",
      brand: "Girls in Tech",
      description: "ONG empowering women in technology",
    },
    {
      id: 3,
      img: "https://res.cloudinary.com/dchrsbres/image/upload/v1725524050/seiglie/colab/bola_opt_ugtgbd.png",
      brand: "MacPlace",
      description: "Web developing and IT support",
    },
    {
      id: 4,
      img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.eypasa.com%2Fimg%2Ficon.jpg",
      brand: "Eypasa",
      description: "Online magazine",
    },
    {
      id: 6,
      img: "https://res.cloudinary.com/dchrsbres/image/upload/v1725714363/seiglie/colab/logo-ayesa-color-1-2822291157_lawvqh.png",
      brand: "Ayesa",
      description: "Global provider of engineering and technology services",
    },
  ];
  return (
    <section className="container p-3   rounded ">
        <h3 className="my-3">Conoce algunas de las empresas con las que he colaborado</h3>
      <article className=" pt-3 transparent  d-flex justify-content-center  gx-2 row ">
        {colabs.map((el) => (
          <article key={el.id} className=" col-xs-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 colab-card g-3">
            <figure className="colab-figure" >
              <img
                width={"120px"}
                className="img-fluid colab-img"
                src={el.img}
                alt={el.brand}
              />
              <figcaption hidden>{el.brand}</figcaption>
            </figure>
          </article>
        ))}
      </article>
      <Link className="btn btn-primary border-0" to={'/contact'}>
        Contacto
      </Link>
      
    </section>
  );
};
