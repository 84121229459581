import React from "react";
import { Link } from "react-router-dom";

export const AvisoLegal = () => {
  return (
    <section className="text-start container fs-5 pt-3 bg-alpha">
      <h3 className="fs-2 text-center mt-5">Aviso Legal</h3>
      <article className="p-5">
        <h4 className="fs-4 my-3 ">Titular del Sitio Web</h4>
        <p className="lh-sm">
          Nombre del Titular: Javier Leyva Seiglie
          <br />
          NIF/CIF: Y8387132N
          <br />
          Domicilio: Sevilla, España
          <br />
          Correo electrónico: <a href="mailto:javier@seiglie.es">javier@seiglie.es</a> 
          <br />
          Teléfono: (+34) 722 390929
        </p>

        <p className="lh-sm my-5">
          El presente Aviso Legal regula el acceso y el uso del sitio web{" "}
          <Link to='/'>www.seiglie.es</Link> (en adelante, "el Sitio Web"), propiedad de Javier
          Seiglie (en adelante, "el Titular"). El acceso y navegación por el
          Sitio Web implican la aceptación expresa y plena de todas y cada una
          de las disposiciones incluidas en este Aviso Legal, que podrán ser
          modificadas o actualizadas periódicamente, por lo que el usuario
          deberá revisarlo en cada ocasión en que acceda al Sitio Web.
        </p>

        <h4 className="fs-4 my-3">1. Objeto del Sitio Web</h4>
        <p className="lh-sm">
          El objetivo de este Sitio Web es proporcionar información sobre los
          servicios y actividades ofrecidos por el Titular, así como permitir la
          interacción con los usuarios a través de formularios de contacto,
          suscripciones, comentarios, y otros mecanismos disponibles.
        </p>

        <h4 className="fs-4 my-3">2. Condiciones de Uso</h4>
        <p className="lh-sm">
          El usuario se compromete a utilizar el Sitio Web de conformidad con la
          ley, el presente Aviso Legal, las normativas aplicables, así como con
          la moral y buenas costumbres generalmente aceptadas y el orden
          público.
        </p>
        <p className="lh-sm">
          Queda prohibido el uso del Sitio Web con fines ilícitos o lesivos, o
          que, de cualquier forma, puedan causar perjuicio a terceros o impedir
          el normal funcionamiento del Sitio Web.
        </p>

        <h4 className="fs-4 my-3">3. Propiedad Intelectual e Industrial</h4>
        <p className="lh-sm">
          Todos los contenidos del Sitio Web (textos, imágenes, gráficos,
          diseño, código fuente, etc.) están protegidos por derechos de
          propiedad intelectual e industrial, de los cuales el Titular es
          titular o legítimo licenciatario.
        </p>
        <p className="lh-sm">
          Queda prohibida la reproducción, distribución, comunicación pública,
          transformación o cualquier otra forma de explotación, sin la
          autorización expresa del Titular.
        </p>

        <h4 className="fs-4 my-3">4. Responsabilidad</h4>
        <p className="lh-sm">
          El Titular no se hace responsable de los daños o perjuicios que puedan
          derivarse del uso incorrecto de los contenidos del Sitio Web. Tampoco
          será responsable de los errores o desactualización de los contenidos
          que puedan aparecer en el Sitio Web, ni de la disponibilidad técnica
          del mismo.
        </p>
        <p className="lh-sm">
          El Titular se reserva el derecho de modificar, eliminar o actualizar
          los contenidos y servicios ofrecidos en el Sitio Web, así como de
          interrumpir temporal o permanentemente el acceso a los mismos sin
          previo aviso.
        </p>

        <h4 className="fs-4 my-3">5. Enlaces Externos</h4>
        <p className="lh-sm">
          El Sitio Web puede contener enlaces a sitios web de terceros. El
          Titular no se responsabiliza por el contenido, políticas de privacidad
          o prácticas de sitios web externos. La inclusión de estos enlaces no
          implica necesariamente la aprobación de los mismos ni la existencia de
          ninguna asociación con sus propietarios.
        </p>

        <h4 className="fs-4 my-3">
          6. Protección de Datos Personales (RGPD y LOPD)
        </h4>
        <p className="lh-sm">
          En cumplimiento de lo dispuesto en el Reglamento (UE) 2016/679 del
          Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a
          la protección de las personas físicas en lo que respecta al
          tratamiento de datos personales y a la libre circulación de estos
          datos (RGPD), así como en la Ley Orgánica 3/2018, de 5 de diciembre,
          de Protección de Datos Personales y garantía de los derechos digitales
          (LOPDGDD), se informa a los usuarios de que cualquier dato personal
          facilitado a través del Sitio Web será tratado de acuerdo con nuestra{" "}
          <Link to="/cookie_policy">Política de Privacidad</Link>.
        </p>
        <p className="lh-sm">
          Los datos personales recabados serán incorporados a un fichero
          automatizado de datos de carácter personal del que es responsable el
          Titular. El tratamiento de los datos se realizará con la finalidad de
          gestionar los servicios ofrecidos en el Sitio Web, así como para
          remitir información comercial o promocional, siempre que el usuario
          haya otorgado su consentimiento explícito.
        </p>
        <p className="lh-sm">
          Los usuarios tienen derecho a acceder, rectificar, suprimir, limitar
          el tratamiento, oponerse y ejercer su derecho a la portabilidad de los
          datos en los términos establecidos en la legislación vigente. Para
          ejercer estos derechos, los usuarios pueden enviar un correo
          electrónico a contacto@seiglie.es, junto con una copia de su DNI u
          otro documento de identidad equivalente.
        </p>

        <h4 className="fs-4 my-3">7. Uso de Cookies</h4>
        <p className="lh-sm">
          El Sitio Web utiliza cookies para mejorar la experiencia del usuario.
          Para más información sobre las cookies utilizadas, consulte nuestra{" "}
          <Link to="/cookie_policy">Política de Privacidad</Link>.
        </p>

        <h4 className="fs-4 my-3">8. Legislación Aplicable y Jurisdicción</h4>
        <p className="lh-sm">
          El presente Aviso Legal se rige por la legislación española. Para
          cualquier controversia que pudiera derivarse del acceso o uso del
          Sitio Web, el usuario y el Titular acuerdan someterse a los juzgados y
          tribunales de la ciudad de Sevilla, renunciando expresamente a
          cualquier otro fuero que pudiera corresponderles, salvo en los casos
          en que la normativa aplicable establezca otro fuero.
        </p>

        <h4 className="fs-4 my-3">9. Modificaciones del Aviso Legal</h4>
        <p className="lh-sm">
          El Titular se reserva el derecho a modificar el presente Aviso Legal
          para adaptarlo a cambios legislativos o a nuevas prácticas
          empresariales. El usuario será informado de dichos cambios mediante la
          publicación en esta misma página.
        </p>

        <h4 className="fs-4 my-3">10. Contacto</h4>
        <p className="lh-sm">
          Si tienes alguna duda o consulta sobre el contenido de este Aviso
          Legal, puedes contactar con nosotros en la dirección de 
          <a href="mailto:javier@seiglie.es">correo
          electrónico</a>.
        </p>
      </article>
    </section>
  );
};
