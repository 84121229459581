export const BrandSection = ({isLogin}) => {
  return (
    <div className={`brand ${isLogin? 'isLogin' : 'isRegister'} `}>
      <div className="heading">
        <figure>
          <img
            src="https://res.cloudinary.com/dchrsbres/image/upload/v1725722301/seiglie/brand/logo192_ecpctt-removebg-preview_r9pwsp.png"
            alt=""
          />
          <figcaption className="color-primary">Seiglie</figcaption>
        </figure>
      </div>
   
    </div>
  );
};
