import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../state/appContext";

export const UserMenu = () => {
    const {store, actions} = useContext(Context);
  return (
    <>
    {store.auth && 
    <ul class="nav justify-content-center">
      <li class="nav-item">
        <Link class="nav-link" aria-current="page" to={'/profile'}>
          Profile
        </Link>
      </li>
      <li class="nav-item">
        <button class="nav-link" onClick={actions.logout}>
          Logout
        </button>
      </li>
    </ul>}
    </>
  );
};
