import React, {useContext, useEffect, useState} from "react";
import { LoginForm } from "./SigninForm";
import { SignupForm } from "./signupForm";
import { BrandSection } from "./brandSection";
import './login_register.css'
import { Context } from "../../state/appContext";
import { useNavigate } from "react-router-dom";

export const LoginRegisterComponent = () => {
  const { store, actions } = useContext(Context);
  const navigate = useNavigate()
  const [endpoint, setEndpoint] = useState('/login');
  const [isLogin, setIsLogin] = useState(true);
  const [keepMeLogged, setKeepMeLogged] = useState(false)
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    password: '',
    passwordCon: '',
  });
  const [errors, setErrors] = useState({
    usernameError: '',
    emailError: '',
    passwordError: '',
    passConfirmError: '',
  });

  useEffect(()=>{
    store.auth && navigate('/')   
  },[store.auth, navigate])


  const handleSwitch = (e) => {
    e.preventDefault();  // Prevent submission when toggling
    setIsLogin(!isLogin);
    endpoint === '/login'? setEndpoint(prev=> prev='/register') : setEndpoint(prev=> prev='/login') 
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!isLogin) {
      if (formData.password !== formData.passwordCon) {
        newErrors.passConfirmError = "Passwords don't match";
        valid = false;
      }
    }

    if (formData.password.length < 7) {
      newErrors.passwordError = 'Password must be at least 8 characters';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setEndpoint(isLogin ? '/login' : '/register');
      formData.keepMeLogged=keepMeLogged
      actions.loginRegister(endpoint, formData);
      // Add success animation logic here
    }
  };

  return (
    <div className="container">
      <section id="formHolder">
        <div className="login_register-wrapper">
          <div className="container mx-auto">
            <BrandSection isLogin={isLogin} />
          </div>
          <div className="container form d-flex">
            {isLogin ? (
              <div className="justify-content-end">
                <LoginForm
                keepMeLogged={keepMeLogged}
                setKeepMeLogged={setKeepMeLogged}
                  data={formData}
                  handleInputChange={handleInputChange}
                  handleSubmit={handleSubmit}
                  handleSwitch={handleSwitch}
                  isLogin={isLogin}
                  errors={errors}
                />
              </div>
            ) : (
              <SignupForm
                data={formData}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                handleSwitch={handleSwitch}
                isLogin={isLogin}
                errors={errors}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
