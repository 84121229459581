import React from "react";
import { Link } from "react-router-dom";
//import PropTypes from "prop-types"

export function FloatingBanner({
  color,
  message,
  header,
  acceptText,
  denyText,
  onAccept,
  onDeny,
}) {
  return (
    <div className="fixed-bottom pb-2 pb-5" style={{ zIndex: "2000" }}>
      <div className="container-fluid  d-flex px-2 px-sm-6 px-lg-8 justify-content-end">
        <div className={`py-3 rounded bg-black bg-gradient shadow-lg p-sm-3`}>
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column justify-content-center">
                <div className={`fw-bold fs-3 text-white mx-auto color-primary`}>{header} <i className="fa-solid fa-cookie-bite"></i></div>
                <div className="ms-3 fw-medium text-white mt-3">
                  <span className="d-md-none">{message}</span>
                  <span className="d-none d-md-inline">{message}</span>
                  <Link to={'/cookie_policy'}>Learn more</Link>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 mt-4 mt-sm-0 w-100 w-sm-auto  mx-sm-0 ms-5 justify-content-end my-3">
              {onAccept && (
                <div className="flex-shrink-0 mt-3">
                  {onAccept && (
                    <button
                      className={`btn btn-primary text-${color}`}
                      onClick={onAccept}
                    >
                      {acceptText}
                    </button>
                  )}
                </div>
              )}

              {denyText && (
                <div className="flex-shrink-0 ms-sm-2 mt-3">
                  <button
                    className={`btn btn-secondary`}
                    onClick={onDeny}
                  >
                    {denyText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// FloatingBanner.propTypes = {
//   color: PropTypes.string.isRequired,
//   message: PropTypes.string.isRequired,
//   header: PropTypes.string.isRequired,
//   acceptText: PropTypes.string.isRequired,
//   denyText: PropTypes.string.isRequired,
//   onAccept: PropTypes.func.isRequired,
//   onDeny: PropTypes.func.isRequired,
// }

// FloatingBanner.defaultProps = {
//   color: "blue",
// }
