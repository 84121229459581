import React, { useContext, useEffect, useState } from "react";
import { Context } from "../state/appContext";
import { Colab } from "../components/colaboration/colab";
import { Hero } from "../components/hero/hero";
import { ConsentForm } from "../components/consent/consent-form";

export const Home = () => {
  const { store, actions } = useContext(Context);
  useEffect(() => {
    actions.test();
    console.log(store);

    // eslint-disable-next-line
  }, []);

  return (
    <main>
      {/* {test? <h2>{test}</h2>: <p>loading</p>} */}
      <Hero />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Colab />
      <ConsentForm />
    </main>
  );
};
