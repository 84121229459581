import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../state/appContext";
import { fetcher } from "../../helpers/fetcher";

export const ProfileAvatarComponent = () => {
  const { store } = useContext(Context);
  const [fileInputState, setFileInputState] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [previewSource, setPreviewSource] = useState(
    store.user?.avatar ||
      "https://res.cloudinary.com/dchrsbres/image/upload/v1726135666/seiglie/brand/webp/logo512_jw9vai_shzorw.webp"
  );
  const [err, setErr] = useState();
  const [success, setSuccess] = useState();
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    setPreviewSource((prev) => (prev = store.user?.avatar));
  }, [store.user]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedFile(file);
    setFileInputState(e.target.value);
    setChanges(true);
  };

  const cleanUpStates = (e) => {
    if (e) e.preventDefault();
    setFileInputState((prev) => (prev = ""));
    setSelectedFile((prev) => (prev = ""));
    setChanges((prev) => (prev = ""));
  };

  const previewFile = (file) => {
    if (file === undefined) {
      cleanUpStates();
    } else {
      const reader = new FileReader();
      try {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setPreviewSource((prev) => (prev = reader.result));
        };
      } catch (error) {
        reader.onerror = () => {
          console.log("error");
        };
      }
    }
  };

  const handleSubmitFile = (e) => {
    e.preventDefault();
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = async () => {
      const data = new FormData();
      data.append("file", selectedFile);
      data.append("upload_preset", "seiglieFront");
      data.append("cloud_name", "dchrsbres");
      data.append('folder', 'seiglie/userAvatar')
      fetch("https://api.cloudinary.com/v1_1/dchrsbres/image/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then(async (respData) => {
          const { data, error } = await fetcher("/user/avatar", "PUT", true, {
            url: respData.secure_url,
          });
          console.log(data);

          if (error) throw new Error(error);

          cleanUpStates();
          setSuccess((prev) => (prev = "Image uploaded succesfully"));
          setChanges((prev) => (prev = false));
          setTimeout(() => setSuccess((prev) => (prev = "")), 3000);
        })
        .catch((err) => {
          console.log(err);
          setErr((prev) => (prev = "something went wrong"));
          setTimeout(() => setErr((prev) => (prev = "")), 3000);
        });

      // const res = await actions.uploadAvatar(reader.result)
      // if (!res.success) {
      //   setErr((prev) => (prev = "error"));
      //   throw new Error("error");
      // }
    };
    reader.onerror = () => {
      console.error("error uploading");
      setErr((prev) => (prev = "something went wrong"));
      setTimeout(() => setErr((prev) => (prev = "")), 3000);
    };
  };

  return (
    <form
      className="form col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex flex-column align-items-center"
      onSubmit={handleSubmitFile}
    >
      <figure className="img-holder img-thumbnail bg-transparent">
        {previewSource && (
          <img className="img-fluid" src={previewSource} alt="preview" />
        )}
        {err ? (
          <div className="mt-2 bg-danger p-1">
            <span className="mt-3">ERROR</span>
          </div>
        ) : (
          ""
        )}
        {success && (
          <div className="mt-2 bg-success p-1">
            <span className="mt-3">Success!</span>
          </div>
        )}
        <figcaption hidden>{store.user?.emmail}'s avatar</figcaption>
      </figure>
      <div className="input-group mb-3 text-white d-flex justify-content-center">
        <input
          id="fileInput"
          type="file"
          accept=".jpg, .jpeg, .png, .webp"
          onChange={handleFileInputChange}
          value={fileInputState}
          className=""
          required
          placeholder="Select img to upload"
        />
      </div>
      {changes && (
        <div className="">
          <input className="btn btn-primary mx-2" type="submit" value="Save" />
          <button
            className="btn btn-outline-danger rounded-1 mx-2"
            onClick={cleanUpStates}
          >
            Cancel
          </button>
        </div>
      )}
    </form>
  );
};
