import React from "react";
import { ProfileAvatarComponent } from "./profile_avatar.component";
import './profile.css'
import { ProfileFormComponent } from "./profile_form.component";

export const ProfileComponent = () => {
  return (
    <section className="row">
         <ProfileAvatarComponent/>
        <ProfileFormComponent />
      {/* <form onSubmit={handleSubmit}></form> */}
    </section>
  );
};
