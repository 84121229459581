import { Link } from "react-router-dom";

export const LoginForm = ({
  keepMeLogged,
  setKeepMeLogged,
  data,
  handleInputChange,
  handleSubmit,
  handleSwitch,
  isLogin,
}) => (
  <div className={`login form-peice bg-primary ${!isLogin ? "switched" : ""}`}>
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="text-white" htmlFor="loginemail">
          Email
        </label>
        <input
          type="email"
          name="email"
          id="loginemail"
          required
          value={data.email}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label className="text-white" htmlFor="loginPassword">
          Password
        </label>
        <input
          type="password"
          name="password"
          id="loginPassword"
          required
          value={data.password}
          onChange={handleInputChange}
        />
      </div>
      <div className="text-white m-3 d-flex  justify-content-start align-items-center ">
        <div className="me-3">
          <input
            type="checkbox"
            checked={keepMeLogged}
            onChange={() => setKeepMeLogged((prev) => (prev = !keepMeLogged))}
            name="keepMeLogged"
            id="keepMeLogged"
          />
        </div>
        Remember me
      </div>

      <div className="text-end my-4">
        <Link to={"/forgot_password"}>Forgot password?</Link>
      </div>
      <div className="CTA d-flex justify-content-center">
        <input className="btn btn-primary border" type="submit" value="Login" />
        <button
          className="btn  btn-secondary border-light switch text-white"
          onClick={handleSwitch}
        >
          I'm New
        </button>
      </div>
    </form>
  </div>
);
