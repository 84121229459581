import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../state/appContext";
import { UserMenu } from "./userMenu/userMenu";

export const Navbar = () => {
  const { store } = useContext(Context);
  const navigate = useNavigate();

  return (
    <nav id="navbar" className="navbar navbar-expand-lg bg-transparent">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <figure>
            <img
              width={40}
              src="https://res.cloudinary.com/dchrsbres/image/upload/v1726135665/seiglie/brand/webp/logo192_ecpctt-removebg-preview_r9pwsp_lcqapr.webp"
              alt="seiglie logo"
            />
            <figcaption hidden>seiglie logo</figcaption>
          </figure>
        </Link>
        <button
          className="navbar-toggler border"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fa-solid fa-bars" ></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
            <li className="nav-item ">
              <Link
                className="nav-link text-white active"
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/learning">
                Learning
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <div
            className={`d-flex align-items-center justify-content-between border border-primary rounded bg-dark bg-gradient p-2 text-white ${
              store.auth ? "  rounded border border-white" : "bg-light"
            }`}
          >
            <span className="fa-solid fa-user-astronaut mx-3 border p-2 rounded-5"></span>
            <div className="me-3">
              {store.auth ? (
                <UserMenu />
              ) : (
                <span className="pointer" onClick={() => navigate("/auth")}>
                  register/login
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
