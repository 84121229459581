import { useContext, useEffect } from "react";
import { Context } from "../state/appContext";
import { useNavigate } from "react-router-dom";

export const AuthHelper = () => {
  const { store, actions } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    const checkAuth = async () => {
      if (!localStorage.getItem("token")) {
        navigate("/auth");
      }
      if (!store.auth) {
        //fetch logged user data
        const check = await actions.getUser();
        if (!check.success) navigate("/auth");
        return true;
      }
    };
    checkAuth();
  }, [store.auth, navigate]);
  return "";
};
