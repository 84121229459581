import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../state/appContext";
import { passwordValidator } from "../../helpers/validation";

export const ProfileFormComponent = () => {
  const { store, actions } = useContext(Context);
  const [formData, setFormData] = useState(store.user);
  const [edit, setEdit] = useState(false);
  const [changes, setChanges] = useState({ email: "", company: "" });
  const [passwordError, setPasswordError] = useState();
  const [passwordReset, setPasswordReset] = useState({
    password: "",
    newPassword: "",
    check: "",
  });

  useEffect(() => {
    setFormData((prev) => (prev = store.user));
  }, [store.user]);

  const handleChange = (e) =>
    setChanges((prev) => (prev = { ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData((prev) => (prev = { ...prev, [edit.name]: "" }));
    actions.updateUser(changes);
  };

  const handleEdit = (val) => {
    if (edit.name === val.name) setEdit((prev) => (prev = ""));
    setEdit((prev) => (prev = val));
  };

  const undoChange = () => {
    setChanges((prev) => (prev = { ...prev, [edit.name]: "" }));
    setEdit((prev) => (prev = ""));
  };

  const handlePasswordChange = (e) =>
    setPasswordReset(
      (prev) => (prev = { ...prev, [e.target.name]: e.target.value })
    );

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if (!passwordValidator(passwordReset.newPassword))
      return setPasswordError(
        "Password should be 8 characters long, include letters, numbers and special characters"
      );
  };

  const handlePasswordClear = () => {
    setPasswordReset(
      (prev) =>
        (prev = {
          password: "",
          newPassword: "",
          check: "",
        })
    );
    setPasswordError(prev => (prev = ""));
  };

  return (
    <form
      className="form col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 bg-alpha mt-4"
      onSubmit={handleSubmit}
    >
      <h3 className="fs-2 my-5">Profile</h3>

      <div className="input-group my-4 d-flex justify-content-center">
        <input
          type="email"
          name="email"
          id="email"
          value={
            changes.email.length > 0 ||
            (formData && formData.email) ||
            "user@example.com"
          }
          onChange={handleChange}
          aria-label="email"
          disabled={edit.name !== "email"}
        />
        <button className="btn" type="button">
          {edit.name === "email" ? (
            <span
              className=" fa-solid fa-x text-danger"
              onClick={undoChange}
            ></span>
          ) : (
            <span
              className="fa-solid fa-pen"
              onClick={(e) => handleEdit({ name: "email" })}
            ></span>
          )}
        </button>
      </div>

      <div className="input-group my-4 d-flex justify-content-center">
        <input
          type="text"
          className=""
          placeholder="company"
          name="company"
          value={
            changes.company.length > 0 || (formData && formData.company) || ""
          }
          onChange={handleChange}
          aria-label="company"
          disabled={edit.name !== "company"}
        />
        <button className="btn" type="button">
          {edit.name === "company" ? (
            <div>
              <span
                className=" fa-solid fa-x text-danger"
                onClick={undoChange}
              ></span>
            </div>
          ) : (
            <span
              className="fa-solid fa-pen"
              onClick={(e) => handleEdit({ name: "company" })}
            ></span>
          )}
        </button>
      </div>

      <section
        onSubmit={handlePasswordReset}
        className="border border-primary p-3 password-update-holder"
      >
        <h4 className="fs-5 mb-auto">Password reset</h4>
        <input
          className="mb-auto"
          type="password"
          name="password"
          id="password"
          placeholder="Actual password"
          value={passwordReset.password}
          onChange={handlePasswordChange}
          aria-label="password"
        />
        {passwordReset.password !== "" && (
          <input
            type="password"
            className="scale-in-center mb-auto"
            name="newPassword"
            id="newPassword"
            placeholder="New password"
            value={passwordReset.newPassword}
            onChange={handlePasswordChange}
            aria-label="newPassword"
            disabled={passwordReset.password === ""}
            autoComplete="off"
          />
        )}
        {passwordReset.newPassword !== "" &&
          passwordReset.newPassword !== passwordReset.password && (
            <input
              type="password"
              className="scale-in-center mb-3 mb-auto"
              name="check"
              id="check"
              placeholder="Repeat new password"
              value={passwordReset.check}
              onChange={handlePasswordChange}
              aria-label="check"
              disabled={passwordReset.newPassword === ""}
            />
          )}

        {passwordError && (
          <div className="bg-danger p-3">
            <span>{passwordError}</span>
          </div>
        )}
        {passwordReset.password !== "" &&
        passwordReset.password === passwordReset.newPassword ? (
          <div className="bg-danger p-3">
            <span>Actual password and new password cannot be the same</span>
          </div>
        ) : (
          ""
        )}
        {passwordReset.check &&
        passwordReset.check !== passwordReset.newPassword ? (
          <div className="bg-danger p-3">
            <span>New password has to match </span>
          </div>
        ) : (
          ""
        )}

        {passwordReset.password !== "" &&
        passwordReset.newPassword !== "" &&
        passwordReset.newPassword === passwordReset.check ? (
          <div className="p-3 mt-3 text-end">
            <input
              type="button"
              className="btn btn-primary mx-3"
              onClick={handlePasswordReset}
              value={"Update"}
            />
            <input
              type="reset"
              className="btn btn-outline-light"
              onClick={handlePasswordClear}
              value={"cancel"}
            />
          </div>
        ) : (
          ""
        )}
      </section>

      {(formData && changes.email !== formData.email) ||
      (formData && changes.company !== formData.company) ? (
        <div className="scale-in-center">
          <input className="btn btn-primary mx-2" type="submit" value="Save" />
          <button
            className="btn btn-outline-danger rounded-1 mx-2"
            onClick={undoChange}
          >
            Cancel
          </button>
        </div>
      ) : (
        ""
      )}
    </form>
  );
};
