export const fetcher = async (endpoint, method, authorization, body) => {
    const headers = new Headers()
    if (method === 'POST' || method === 'PUT' ) {
        headers.append('Content-Type', 'application/json')   
    } 
    if (authorization) {
        headers.append('Authorization', localStorage.getItem('token'))
    }
    const opt = {
        method,
        headers,
        ...(method === 'POST' || method === 'PUT' ? { body: JSON.stringify(body) } : {})

    }
   
    try {
        const resp = await fetch('/api'+endpoint, opt)

        if (!resp.ok) {
            throw new Error(`Error fetching: ${resp.statusText}`);
        }
        const data = await resp.json()        
        return {success: true, data}

    } catch (error) {
        return {success: false, error: error.message}
        
    }
}