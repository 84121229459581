import { fetcher } from "../helpers/fetcher"; //(endpoint, method, authorization, body)

export const userLogout = (set) => {
    try {
        localStorage.removeItem('token');
        set({user: null, auth: false});
        return {success: true}
    } catch (error) {
        console.log('Error ---> ',error.message)
        return {success: false, error: error.message}
    }
}

export const userLoginRegister = async (set, endpoint, credentials) => {
    try {
     const {data, error} = await fetcher('/user'+endpoint, 'POST', null, credentials) 
        if (error) throw new Error(error)              
        localStorage.setItem('token', data.token)
        return {success: true, user: data.user, auth:true}
    } catch (error) {
        console.log('Error ---> ',error.message)
        return {success: false, error: error.message}
    }
}

export const getUser = async () => {
    try {
        const {data, error} = await fetcher('/user/get_user', 'GET', true)
        if (error) throw new Error(error)
        return {success: true, user: data.user, auth:true}
    } catch (error) {
        console.log('Error ---> ',error.message)
        return {success: false, error: error.message}
    }
}

export const uploadImage = async (base64EncodedImage) => {
    try {
      const {data, error} = await fetcher(
        "/user/avatar",
        "PUT",
        localStorage.getItem("token"),
        {
          img: base64EncodedImage,
        }
      );

    //   console.log("fetcher ----> ", data);

      if (!data.success) {
        throw new Error(error);
      }
      return {success: true, user: data.user}

    } catch (error) {
      console.error(error);
      return {success: false, error: error.message}
    }
  };
// export const userToken = (set) => {
//     try {
//         localStorage.getItem('token')
//         return {ok: true}
//     } catch (error) {
//         console.log('Error ---> ',error.message)
//         return {ok: false, error: error.message}
//     }
// }



// export const user = (set) => {
//     try {
//         return {success: true}
//     } catch (error) {
//         console.log('Error ---> ',error.message)
//         return {success: false, error: error.message}
//     }
// }